//@ts-ignore
// import Cookies from 'js-cookie';
// import axios from 'axios';

// const backendBaseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

// const axiosInstance = axios.create({
//   baseURL: backendBaseUrl,
//   timeout: 10000,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// axiosInstance.interceptors.request.use(
//   config => {
//     const token = Cookies.get('token');
//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`;
//     }
//     // config.headers['Accept-Language'] = 'ua';

//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   },
// );

// axiosInstance.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     return Promise.reject(error);
//   },
// );

// export default axiosInstance;


//@ts-ignore
import Cookies from 'js-cookie';
import axios from 'axios';

const backendBaseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: backendBaseUrl,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    const token = Cookies.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = Cookies.get('refreshToken');
        if (refreshToken) {
          const response = await axios.post(`${backendBaseUrl}/refresh`, {
            token: refreshToken,
          });
          const newToken = response.data.token;
          Cookies.set('token', newToken);

          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        Cookies.remove('token');
        Cookies.remove('refreshToken');
        localStorage.clear();
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;

