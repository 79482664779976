import { FC } from 'react';
import {
  AmazonTransit,
  EmptyGoods,
  InnerLabel,
  Label,
  ListContainer,
  ListInfo,
  ServiceList,
  TariffItem,
  TariffList,
  TariffTotal,
  Total,
  TranzitWrapper,
} from './styles';
import { useMobile, useTablet } from '../../constants/breakpoints';
import { useDetailsContext } from '../Threads/DetailsContext';
import { getFulFillmentTaskLabel } from './utils';

interface ITariffCalculateProps {
  config?: any;
}

const TariffCalculate: FC<ITariffCalculateProps> = () => {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isDesktop = !isMobile && !isTablet;
  const { config, commonConfig } = useDetailsContext();

  const packingTypesConfig = (type: string) => {
    switch (type) {
      case 'box':
        return 'Коробка';
      case 'pallet':
        return 'Палета';
      case 'bag':
        return 'Мішок';
      case 'oversized':
        return 'Негабарит';
    }
  };

  const dimensionsTypesConfig = ({
    price: dimensionsPrice,
    volume: dimenstionsVolume,
    gross_weight: dimensionsGrossWeight,
    net_weight: dimensionsNetWeight,
  }: any) => {
    const price = dimensionsPrice ? `$ ${dimensionsPrice}` : '';
    const volume = dimenstionsVolume ? `${dimenstionsVolume} м3` : '';
    const gross = dimensionsGrossWeight
      ? `${dimensionsGrossWeight} кг брутто`
      : '';
    const net = dimensionsNetWeight ? `${dimensionsNetWeight} кг нетто` : '';

    return [volume, gross, net, price].filter(Boolean).join(', ');
  };

  return (
    <div>
      {(isDesktop) && (
        <Label>
          <div>Дані для розрахунку тарифу:</div>
          {
            config?.data?.fulfillment_task && (
              <InnerLabel>
                <div>Завдання по вантажу:</div>
                <AmazonTransit>{getFulFillmentTaskLabel(commonConfig?.fulfillment_tasks, config?.data?.fulfillment_task)}</AmazonTransit>
              </InnerLabel>
            )
          }
        </Label>
        )}
      {(!isDesktop && config?.data?.fulfillment_task) && (
        <TranzitWrapper>
          <AmazonTransit>{getFulFillmentTaskLabel(commonConfig?.fulfillment_tasks, config?.data?.fulfillment_task)}</AmazonTransit>
        </TranzitWrapper>
      )}
      <ListContainer>
        {config?.goods.length ? (
          <>
            {config?.goods?.map((good: any) => {
              const dimenstionsVolume = Number.isInteger(good?.data?.dimensions?.volume) ? good?.data?.dimensions?.volume : good?.data?.dimensions?.volume?.toFixed(3);
              const dimensionsGrossWeight = Number.isInteger(good?.data?.gross_weight) ? good?.data?.gross_weight : good?.data?.gross_weight?.toFixed(3);
              const dimensionsNetWeight = Number.isInteger(good?.data?.net_weight) ? good?.data?.net_weight : good?.data?.net_weight?.toFixed(3);
              const dimensionsPrice = good?.price;

              return (
                <TariffList>
                  <TariffItem>
                    {packingTypesConfig(good?.packaging_type)} {good?.amount}{' '}
                    шт., {good?.name}
                  </TariffItem>
                  <TariffItem>
                    {dimensionsTypesConfig({
                      price: dimensionsPrice,
                      volume: dimenstionsVolume,
                      gross_weight: dimensionsGrossWeight,
                      net_weight: dimensionsNetWeight,
                    })}
                  </TariffItem>
                </TariffList>
              );
            })}

            <TariffTotal>
              {dimensionsTypesConfig({
                price: config?.goods_total?.total_price?.toFixed(2),
                volume: Number.isInteger(config?.goods_total?.total_volume) ? config?.goods_total?.total_volume : config?.goods_total?.total_volume?.toFixed(3),
                gross_weight: Number.isInteger(config?.goods_total?.total_gross_weight) ? config?.goods_total?.total_gross_weight : config?.goods_total?.total_gross_weight?.toFixed(3),
                net_weight: Number.isInteger(config?.goods_total?.total_net_weight) ? config?.goods_total?.total_net_weight : config?.goods_total?.total_net_weight?.toFixed(3),
              })}
            </TariffTotal>
          </>
        ) : (
          <EmptyGoods>
            <p>
              {isMobile
                ? 'Тут мають бути дані для розрахунку тарифу, але поки що тут порожньо…'
                : 'Поки що тут порожньо'}
            </p>
          </EmptyGoods>
        )}
      </ListContainer>
    </div>
  );
};

export default TariffCalculate;
